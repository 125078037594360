import {stringKeyObject} from '../types/common'
import {OptionType} from '../components/Select/SelectTypes'
import {sortDirection} from '../types/filter'

const FILTER_PARAM_PREFIX = 'filter'

export const NAMED_FILTER_DEVICE_OVERVIEW = 'device_overview'
export const NAMED_FILTER_FAQ = 'faq'
export const NAMED_FILTER_USERS = 'users'
export const NAMED_FILTER_DEVICE_ADDRESSES = 'device-addresses'
export const NAMED_FILTER_COMPANIES = 'companies'
export const NAMED_FILTER_COMPANY_RELATIONS = 'company-relations'

export type namedFilter =
  typeof NAMED_FILTER_DEVICE_OVERVIEW
  | typeof NAMED_FILTER_FAQ
  | typeof NAMED_FILTER_USERS
  | typeof NAMED_FILTER_DEVICE_ADDRESSES
  | typeof NAMED_FILTER_COMPANIES
  | typeof NAMED_FILTER_COMPANY_RELATIONS
  ;

export type namedFilterFieldDeviceOverview =
  'from'
  | 'until'
  | 'longDescription'
  | 'description'
  | 'serialNumber'
  | 'deviceId'
  ;
export type namedFilterFieldFaq =
  'categories.title'
  | 'question'
  | 'answer'
  ;
export type namedFilterFieldUsers =
  'status'
  | 'roles'
  | 'firstName'
  | 'lastName'
  | 'email'
  ;
export type namedFilterFieldDeviceAddresses =
  'search'
  | 'company'
  | 'calibrationFrom'
  | 'calibrationUntil'
  | 'from'
  | 'until'
  ;
export type namedFilterFieldCompanies = 'search';
export type namedFilterFieldCompanyRelations = 'search';

export type namedFilterField =
  namedFilterFieldDeviceOverview
  | namedFilterFieldFaq
  | namedFilterFieldUsers
  | namedFilterFieldDeviceAddresses
  | namedFilterFieldCompanies
  | namedFilterFieldCompanyRelations
  ;

type filterOperator =
  'eq'
  | 'neq'
  | 'lt'
  | 'lte'
  | 'gt'
  | 'gte'
  | 'in'
  | 'nin'
  | 'contains'
  | 'member_of'
  | 'starts_with'
  | 'end_with'
  | 'like'
  | 'is_null'
  ;

interface sortParamConfig {
  field: string | number,
  direction?: sortDirection
}

interface filterParamConfigBase {
  value: string
}

export interface simpleFilterParamConfig extends filterParamConfigBase {
  field: string
  operator: filterOperator
}

export interface namedFilterParamConfig extends filterParamConfigBase {
  name: namedFilter
  field: namedFilterField
}

export type filterParamConfig = Array<simpleFilterParamConfig | namedFilterParamConfig>;

function isNamedFilterParamConfig(paramConfig: simpleFilterParamConfig | namedFilterParamConfig): paramConfig is namedFilterParamConfig {
  return 'name' in paramConfig
}

function filterOptionBase(candidate: OptionType, input: string, extendedFilter?: (candidate: OptionType, input: string) => boolean): boolean {
  let inputFormatted = input.trim().toLowerCase()
  if (candidate.label.toLowerCase().includes(inputFormatted))
    return true
  else if (extendedFilter)
    return extendedFilter(candidate, input)
  return false
}

export default class FilterService {
  static filterStoreIdPrefix = 'filterConfig'
  static filterStoreIds = {
    'DeviceOverview': FilterService.filterStoreIdPrefix + 'DeviceOverview',
    'DeviceAddressOverview': FilterService.filterStoreIdPrefix + 'DeviceAddressOverview',
    'CompanyOverview': FilterService.filterStoreIdPrefix + 'CompanyOverview',
    'UserManagement': FilterService.filterStoreIdPrefix + 'UserManagement',
    'MyTeam': FilterService.filterStoreIdPrefix + 'MyTeam',
  }

  static getSortParams(...sortConfig: sortParamConfig[]): stringKeyObject {
    let params = {}
    sortConfig.forEach(paramConfig => {
      Object.assign(params, {
        ['sort[' + paramConfig.field + ']']: paramConfig.direction,
      })
    })
    return params
  }

  static getFilterParams(...filterConfig: filterParamConfig): stringKeyObject {
    let params = {}

    filterConfig.forEach(paramConfig => {
      if (!paramConfig.value || paramConfig.value === "undefined")
        return

      let paramKey = FILTER_PARAM_PREFIX + '['
      if (isNamedFilterParamConfig(paramConfig))
        paramKey += paramConfig.name + '][' + paramConfig.field + ']'
      else
        paramKey += paramConfig.field + '][' + paramConfig.operator + ']'
      Object.assign(params, {
        [paramKey]: paramConfig.value,
      })
    })

    return params
  }

  static getInValueFromSelectValues(values: OptionType[]): string {
    if (values.length <= 0)
      return ''
    let valueKeys: Array<string | number> = []
    values.forEach(value => valueKeys.push(value.value))
    return valueKeys.join(',') ?? ''
  }

  static formatDate(date: Date): string {
    return date.toISOString()
  }

  static filterOptionCompany(candidate: OptionType, input: string): boolean {
    return filterOptionBase(candidate, input, (cand, search) => {
      if (cand.data && cand.data.hasOwnProperty('data')) {
        let data = cand.data.data
        if (data.hasOwnProperty('companyId') && typeof data.companyId === 'string' && data.companyId.toLowerCase().includes(search))
          return true
        if (data.hasOwnProperty('internalId') && typeof data.internalId === 'string' && data.internalId.toLowerCase().includes(search))
          return true
      }
      return false
    })
  }

  static filterOptionAddress(candidate: OptionType, input: string): boolean {
    return filterOptionBase(candidate, input, (cand, search) => {
      if (cand.data && cand.data.hasOwnProperty('data')) {
        let data = cand.data.data
        if (data.hasOwnProperty('addressId') && data.addressId.toLowerCase().includes(search))
          return true
      }
      return false
    })
  }
}